html {
    font-size: 62.5%;
    /* RESETS BASELINE FONT-SIZE */
}

body {
    margin: 0 auto;
    font-family: arial, sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.0rem;
    color: #141516;
}

h1 {
    margin: 0 0 1.6rem 0;
    padding: 0;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
}

h2 {
    margin: 3rem 0 0 0;
    padding: 0;
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 3rem;
}

h2 + p {
    margin-top: 1rem;
}

h3 {
    margin: 3rem 0 0.6rem 0;
    padding: 0;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.6rem;
}

h3 + p {
    margin-top: 1rem;
}

h4 {
    margin: 3rem 0 0 0;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.0rem;
}

h4 + p {
    margin-top: 1rem;
}

p {
    max-width: 65rem;
    /* MAX LINE-WIDTH FOR READABILITY */
    margin: 1rem 0 0.5rem 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 2.2rem;
}

ul {
    line-height: 2.4rem;
}

a {
    text-decoration: underline;
    color: #004e6c;
    transition-duration: 0.2s;
}

a:hover {
    background: #e7ebed;
}

a.visited {
    text-decoration: underline;
    color: #a42104;
}

a.darkBG {
    color: #c4cbcf;
}

a.darkBG:hover {
    background: #141516;
}

a.heading {
    color: #141516;
}

a.heading:hover {
    text-decoration: underline;
}

.manchet {
    margin: 1rem 0 2rem 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.2rem;
}

.manchet + p {
    margin-top: 3rem;
}

.stil-site {    
    max-width: 1200px;
    min-width: 768px;
    margin: 0 auto;
    padding: 0 10px 20px 10px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.stil-header {
    display: table;
    width: 100%;
    height: auto;
}

.stil-header-content {
    float: left;
    width: 100%;
}

.stil-header-content em {
    float: left;
    display: inline-block;
    margin: 50px 0 10px 0;
    /* SPECIFICALLY FOR STIL LOGO USE WITHOUT SEARCH. MARGIN-BOTTOM NEEDS ADJUSTMENT TO 10PX IF SEARCH IS VISIBLE. MARGIN ADJUSTMENTS ARE NEEDED TO COMPENSATE FOR SITE NAMELENGTH AND CHOICE OF LOGO */
    /* margin:68px 0 18px 0; /* USED WHEN SEARCH IS VISIBLE */
    /* margin:36px 0 18px 0; /* USED WHEN LOGGED IN */
    padding: 0;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: bold;
    line-height: 2.4rem;
}

.stil-header-content em a {
    color: #000000;
    text-decoration: none;
}

.stil-header-content em a:hover {
    background: transparent;
}

.stil-header-content img {
    /* display: none; */
    /* HIDING LOGO WHEN LOGGED IN TO SAVE SPACE */
    float: right;
    height: 59px;
    width: 263px;
    margin: 10px 0 10px 0;
}

.stil-global-search {
    /* HIDDEN ON SMALL SCREENS */
    clear:left;
    display: none;
    /* SET TO BLOCK TO VIEW SEARCH */
    height: 28px;
    margin-bottom: 18px;
    padding: 0;
}

.stil-global-search span {
    display: table-cell;
}

.stil-global-search input {
    width: 480px;
    height: 28px;
    margin: 0;
    padding: 3px;
    border: 1px solid #000;
    border-radius: 5px;
    vertical-align: middle;
}

.stil-global-search button {
    margin: 0;
    padding: 0;
    width: 28px;
    height: 28px;
    background: transparent;
    border: 0;
    vertical-align: middle;
}

.stil-global-search path {
    fill: #4f5355;
}

.stil-global-search:hover path {
    fill: #83898c;
}

.stil-topmenu {
    clear: both;
    float: left;
    width: 100%;
    min-height: 24px;
    padding: 0;
    border-top: 1px solid #c4cbcf;
    border-bottom: 1px solid #c4cbcf;
}

.stil-topmenu .topmenuLeft,
.stil-topmenu .topmenuRight {
    display: block;
    float: left;
    list-style: none;
    width: auto;
    margin: 0;
    padding: 0;
}

.stil-topmenu .topmenuRight {
    float: right;
}

.stil-topmenu .topmenuLeft li,
.stil-topmenu .topmenuRight li {
    display: inline-block;
    margin: 0 2.4rem 0 0;
    padding: 0;
}

.stil-topmenu .topmenuRight li {
    margin: 0;
}

.stil-logged-in-user {
    float: right;
    margin: 0;
    font-size: 1.4rem;
}

.logout-in-modal {
    float: right;
    margin: 1rem;
    font-size: 1.4rem;
}

.stil-topmenu .topmenuLeft li a {
    color: #4f5355;
    /* STIL ANTRACITGRÅ 2 */
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 5rem;
    padding: 1.2rem 0;
    text-decoration: none;
    background: #ffffff;
}

.stil-topmenu .topmenuLeft li a:hover {
    color: #004e6c;
    border-bottom: 6px solid #004e6c
}

.stil-primary-button {
    display: inline-block;
    margin-top: 8px;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
    text-decoration: none !important;
    background: #000;
    border-radius: 5px;
}

.stil-primary-button:hover {
    background: #83898c;
}

.stil-topmenu .mobileMenuButton {
    display: none;
    /* HIDES BUTTON ON BIG SCREENS */
}

.stil-main-content {
    clear: both;
    min-height: 300px;
}

.stil-footer {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 80px;
    border-top: 3px solid #000;
}

.stil-footerblock {
    width: 33.333%;
    margin: 0;
    float: left;
}

.stil-footer em {
    display: block;
    max-width: 65rem;
    margin: 40px 0 10px 0;
    padding: 0;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase
}

.stil-footer p {
    max-width: 65rem;
    margin: 0;
    padding: 0 0 20px 0;
}

.stil-footer a {
    display: inline-block;
}
